import shortid from "shortid";

export const blankPage = {
  id: shortid.generate(),
  name: 'New Page',
  headline: 'New Page',
  displayHeaderBanner: true,
  displayFooter: true,
  widgets: []
}

export const defaultPagesList = ["1"]