/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import supabase from "utils/supabase";
import call from 'api/call';
import { APIS } from 'api/constants';
import snackbar from "stores/snackbarStore";
import stores from 'stores';
import dataStore from "stores/dataStore";
import customersStore from 'stores/Customer';
import assumptionStore from 'stores/assumptionStore';
import portfoliosStore from 'stores/portfoliosStore';
import assetsOverviewStore from 'stores/assetsOverviewStore';



const AuthProvider = observer(({ children }) => {
  const { organisationSettings } = stores;

    const history = useHistory()

    useEffect(() => {
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        if (!session || !localStorage.getItem("TOKEN")) {
          localStorage.clear();
          history.push("/login")
        }
      })

      const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {

        if(!_event || !session) {
          return
        }
        if (_event === 'INITIAL_SESSION') {
          console.log("AUTHPROVIDER INITIAL_SESSION")
          await organisationSettings.initializeOrganisationSettings(session.user.id);
          await Promise.all([
              dataStore.fetchPreviousBankDate(),
              dataStore.fetchCurrencyCodes(),
              customersStore.getAll(),
              assumptionStore.getAssumptions(),
              portfoliosStore.fetchAllPortfolios(),
              assetsOverviewStore.fetchAssets()
          ]);
        } else if (_event === "SIGNED_IN") {
          console.log("SIGNED_IN")
          // handle sign in event
        } else if (_event === 'SIGNED_OUT') {
          console.log("SIGNED_OUT")
          // handle sign out event
        } else if (_event === 'PASSWORD_RECOVERY') {
          console.log("PASSWORD_RECOVERY")
          // handle password recovery event
        } else if (_event === 'TOKEN_REFRESHED') {
          console.log("TOKEN_REFRESH")
          const response = await call(APIS.LOGIN, { Token: session.access_token });
          if(!response.success) {
              snackbar.handleOpen("Unable to verify user. Logging you out.", "error")
              localStorage.clear();
              history.push("/login")
          } else {
            localStorage.setItem("TOKEN", response.message.Token);
            localStorage.setItem("ORGANISATIONID", response.message.MasterUserID);
            localStorage.setItem("TOKEN", JSON.stringify(session.access_token));
          }
  
        }
      })

      return () => subscription.unsubscribe()
    }, [history, organisationSettings])

    return (
      <>{children}</>
    )
})

export default AuthProvider;
