import { action, makeObservable, observable } from "mobx";
import { dateToInt } from "utils/dateFormatters";
import portfoliosStore from "stores/portfoliosStore";
import { internalapiCall } from "api/call";
import { IRISK_BO_API_URL } from "api/constants";
import PDFEditorStore from "stores/PDFEditor";
import { toJS } from "mobx";


class PDFEditorSettingsStore {
    loading = false;
    settings = null;
    uiSettings = {
        pageZoom: 0.8,
    }

    constructor() {
        makeObservable(this, {
            loading: observable,
            settings: observable,
            updateSettingsValue: action,
            uiSettings: observable,
            updateUISettingsValue: action,
            changeCalcDate: action,
        });
    }

    initialize = (settings) => {
        this.settings = settings;
    }

    async updateSettingsValue(key, value) {
        const reportId = toJS(PDFEditorStore.report._id)
        this.settings[key] = value;
        PDFEditorStore.applySettingsToPages(this.settings);
        await internalapiCall("POST", `${IRISK_BO_API_URL}/report/pdf/update-settings`, { reportId: reportId, key, value });
    }

    updateUISettingsValue = (key, value) => {
        this.uiSettings[key] = value;
    }

    async changeCalcDate(customerId, portfolioId, newDate, key) {
        this.settings[key] = dateToInt(newDate)
        
        await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId, this.startDate, this.endDate);
      }
}

const store = (window.pdfEditorSettingsStore = new PDFEditorSettingsStore());

export default store;