
import { APIS } from "api/constants";
import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { mobXStoreHelper } from "utils/mobXStoreHelper"

class TransactionsStore {
    assetTransactions = [];
    currencyTransactions = [];
    transList = [];

    constructor() {
        makeObservable(this, {
            transList: observable,
            fetchPortfolioTransactions: action.bound,
        });
    }

    //Fetch
    async fetchPortfolioTransactions(customerId, portfolioId) {
        const params = { CustomerID: Number(customerId), PortfolioName: portfolioId }
        mobXStoreHelper(APIS.FETCH_TRANSACTIONS, params, async (response) => {
            this.transList = response.message.TransList.map((el) => ({ ...el, id: uuidv4() }));
        })
    }
}

// this makes it possible to access it via console
const store = (window.transactionsStore = new TransactionsStore());

export default store;
