// This store is going to have data for the widgets that we are going to use in our application.
// Initially we will only do repporting on each portfolio, but we can expand this to other areas of the application later on.
import { APIS, API_URL } from "api/constants";
import { action, makeObservable, observable, runInAction } from "mobx";
import call from 'api/call';
import snackbarStore from "stores/snackbarStore";
import PDFEditorStore from "stores/PDFEditor";
import { widgetConstructor } from "./utils";
import DashboardEditorStore from "stores/DashboardEditor";
import dataStore from "stores/dataStore";
import { getDateRangeByNumberFromPeriod } from "utils/dateFormatters";
import { REPORT_TYPES } from "Pages/ReportEditor/constants";
import { widgetTypes } from "./constants";

class PortfolioWidgetsStore {
    loading = false                       // 
    fetchedId = null
    error = null
    widgets = {}
    widgetsMenu = {}
    widgetsData = {}
    reportItem = null
    breakpoint = null
    startDate = null
    endDate = null

    constructor() {
        makeObservable(this, {
          initializeHistoricalNoDataWidgets: action,
            selectWidget: action,
            widgetsMenu: observable,
            widgets: observable,
            addDynamicWidget: action,
            loading: observable,
            fetchedId: observable,
            error: observable,
            widgetsData: observable,
            injectWidgetData: action,
            reportItem: observable,
            breakpoint: observable,
            startDate: observable,
            endDate: observable,
            setFetchedId: action,
            // fetchDataForWidgetWithIndependentURL: action,
            addDynamicWidgetAndData: action
        });
    }

    // Used to set the id for the given report being rendered. This is done to prevent rerenders
    setFetchedId(id) {
      this.fetchedId = id
    }

    addDynamicWidgetAndData(widget, data) {
      this.addDynamicWidget(widget)
      this.injectWidgetData(widget.id, data)
    }

    // Used to add widget to the list of widgets to choose from
    addDynamicWidget(widget) {
      // Avoid dublicates
      if(this.widgets[widget.id]) {
        return
      }

      this.widgets = {
        ...this.widgets,
        [widget.id]: widget
      }
    }

    // Used to add data to the widgets
    injectWidgetData(widgetId, data) {
      const widgetDataElement = {
        ...this.widgetsData,
        [widgetId]: data
      }

      this.widgetsData = widgetDataElement
    }

    // Used to select a widget and add it to the grid layout
    selectWidget(newWidget, reportType, pageId = null) {
      if(reportType === REPORT_TYPES.PDF) {
        PDFEditorStore.addWidgetToLayout(newWidget, pageId)
      }
      if(reportType === REPORT_TYPES.DASHBOARD) {
        DashboardEditorStore.addWidgetToLayout(newWidget)
      }
    }

    // Dynamic widgets are widgets with their own url to fetch data from
    // Here we initialize the widgets, but they do not yet have any data
    async initializeHistoricalNoDataWidgets(customerId, portfolioId) {
        runInAction(() => {
          this.loading = true;
        });
        try {          
          const response = await call(APIS.GET_CALCULATION_RESULT_HIST_CAT, { CustomerID: Number(customerId), PortfolioName: portfolioId });
          

          if(!response.message.Category) {
            runInAction(() => {
              this.loading = false
            });

            return
          }
          
          await response.message.Category.forEach(({CalcName, Category}) => {

            if(CalcName !== "AUM") {
              return
            }

            const label = `Portfolio ${CalcName.split('_').join(' ')}`;

            // TODO: If more details is needed, here is a good place to add it for independent widgets.
            // Although hardcoding is not ideal for this, it is the best option for now as the alternative
            // is to fix the backend api.

            const widget = widgetConstructor(CalcName, label, Category, widgetTypes.historical)
            this.addDynamicWidget(widget)
          })
        

          runInAction(() => {
            this.loading = false;
          });
          
        } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
          runInAction(() => {
            this.loading = false;
            this.error = error;
          });
        }
    }

    // This function is used to fetch data for widgets that has an independant url / api call
    async fetchDataForWidgetWithIndependentURL(widgetItem, customerId, portfolioId, reportType, period) {
      const { FromDate, ToDate } = getDateRangeByNumberFromPeriod(period, dataStore.calcDate)
      const params = {
          CustomerID: Number(customerId),
          PortfolioName: portfolioId,
          FromDate,
          CalcName: widgetItem.id,
          ToDate,
      };

      try {
        const response = await call(`${API_URL}/${widgetItem.independentFetchingURL}`, params);
        let data = {
          BM: [],
          CalcName: widgetItem.id,
          CustomerID: customerId,
          ErrorCode: response.message.ErrorCode,
          ErrorText: response.message.ErrorText,
          HistData: [],
          InvestAmount: 0,
          PortfolioName: portfolioId
        }
        if(response.message.ErrorCode === 0) {
          data = response.message
        }
        this.injectWidgetData(widgetItem.id, data);
      } catch (error) {
          snackbarStore.handleOpen(error.toString(), 'error');
      }
  }
}

const store = (window.portfolioWidgetsStore = new PortfolioWidgetsStore());

export default store;