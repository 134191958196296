/* eslint-disable no-unused-vars */
import { action, makeObservable, observable } from 'mobx';

class SnackbarStore {
    text = "";
    open = false;
    delay = 5000;
    severity = "info"

    constructor() {
        makeObservable(this, {
            open: observable,
            handleOpen: action,
            handleClose: action,
            handleSetText: action,
            handleSetSeverity: action
        });
    }

    handleOpen(text, severity = "info") {
        this.open = true
        this.handleSetText(text);
        this.handleSetSeverity(severity)
    }

    handleClose() {
        this.open = false
    }

    handleSetText(text) {
        this.text = text
    }

    handleSetSeverity(severity) {
        this.severity = severity
    }
}

// this makes it possible to access it via console
const snackbarStore = (window.snackbarStore = new SnackbarStore());

export default snackbarStore;
