export const COLS = {
  lg: 6,
  paper: 3,
}

export const A4_HEIGHT_PX = 1123; // Height of A4 page in pixels at 96 DPI

export const SMALL_PAPER = 1
export const MEDIUM_PAPER = 1
export const LARGE_PAPER = COLS.paper

export const SMALL_LG = COLS.lg / 3
export const LARGE_LG = COLS.lg

export const ROWS = 12
export const BREAKPOINTS = {
  lg: 1000,
  paper: 100,
}
export const ROW_HEIGHT = 80
export const RGL_PADDING = 12
export const BREAKPOINT_NAMES = {
  lg: 'lg',
  paper: 'paper'
}

// Note these are mapped based on widgetIds and must not be changed.
export const widgetCategoryLabels = {
  'groups-distribution': 'Groups Distribution',
  "sector-distribution": 'Sector Distribution',
  'risk-classification': 'Risk Class Distribution',
  'liquidity-distribution': 'Liquidity Distribution',
  'currency-exposure': 'Currency Distribution',
  'bond-issuers': 'Bond Issuer Distribution',
  'bond-class': 'Bond Type Distribution',
  'benchmark-distribution': 'Benchmark Distribution',
  'asset-class-distribution': 'Asset Class Distribution',
  "holding": 'Holding',
  'keyfigures': 'Historical Key Figures',
  "target": 'Target',
  'return': 'Return',
  'customer-information': 'Customer information',
  'organisation-information': "Organisation information",
  "advisor-information": "Advisor information"
}
export const widgetCategories = {
    // distribution: 'distribution',     // Is a pie chart by default
    // comparison: 'comparison',         // is a bar chart by default
    // AUM_NAV: 'AUM_NAV',               // is a line chart by default
    // HIST: 'HIST',                     // is an area chart by default
    // WEIGHT: 'WEIGHT',                 // is an area chart by default
    // holding: 'holding',               // is a table by default
    // stat: 'stat',                     // is a number by default
    // HistoricalTable: "HistoricalTable", // is a table by default
    // allocation: "allocation"          // is a pie chart by default

    groupsDistribution: 'groups-distribution',
    sectorDistribution: 'sector-distribution',
    riskClassDistribution: 'risk-classification',
    liquidityDistribution: 'liquidity-distribution',
    currencyDistribution: 'currency-exposure',
    bondIssuerDistribution: 'bond-issuers',
    bondTypeDistribution: 'bond-class',

    benchmarkDistribution: 'benchmark-distribution',
    assetClassDistribution: 'asset-class-distribution',
    holding: 'holding',
    target: 'target',
    historicalReturn : 'return',
    historicalKeyFigures: 'keyfigures',
    customerInformation: 'customer-information',
    organisationInformation: 'organisation-information',
    advisorInformation: "advisor-information"
}

export const WIDGET_TYPES = {
  pieChart: 'pieChart',
  barChart: 'barChart',
  lineChart: 'lineChart',
  areaChart: 'areaChart',
  table: 'table',
  holding: 'holding',
  number: 'number',
}

export const insightsWidgetBaseData = {
  static: false,
  resizeHandles: ["s", "e", "se"],
  minW: 2,
  minH: 2,
  maxW: COLS.lg,
  isResizable: true,
}

export const pdfWidgetBaseData = {
  static: false,
  minW: 1,
  minH: 1,
  resizeHandles: ["s", "e", "se"],
  maxW: COLS.paper,
  isResizable: true,
}

export const widgetTypes = {
  distribution: "distribution",
  distributionList: "distributionList",
  holding: "holding",
  historical: "historical",
  target: "target",
}

export const widgetCategoriesStaticData = {
  distribution: {
    paths: { table: 'table', chart: 'chart' },
    sizes: { paper: { w: LARGE_PAPER, h: 2 }, lg: { w: 4, h: 3 } }
  },
  target: {
    paths: { chart: 'data' },
    sizes: {
      paper: { w: LARGE_PAPER, h: 2 },
      lg: { w: LARGE_LG, h: 2 },
    }
  },
  holding: {
    paths: {
      rows: 'rows',
      portfolio: 'portfolio',
      columnVisibilityModel: 'columnVisibilityModel',
    },
    sizes: {
      paper: { w: LARGE_PAPER, h: 2 },
      lg: { w: LARGE_LG, h: 3 },
    }
  },
  historical: {
    independentFetchingURL: 'iRisk_GetCalculationResultHist',
    paths: {
      chart: 'HistData',
      investAmount: 'InvestAmount',
    },
    sizes: {
      paper: { w: LARGE_PAPER, h: 2 },
      lg: { w: LARGE_LG, h: 2 },
    }
  }
}