import React, { useEffect, useState } from 'react';
import { TextField, Button, Stack, MenuItem, Select, FormHelperText, FormControl, InputLabel } from '@mui/material';
import supabase from 'utils/supabase';
import stores from "stores";
import { useHistory } from 'react-router-dom';


const NewOrganisation = (session) => {
    const history = useHistory();
    const { snackbar } = stores;
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [positions, setPositions] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [selectedPosition, setSelectedPosition] = useState(null);
    
    // Get possitions list
    useEffect(() => {
        const getOrganisationRolesList = async () => {
            const { data, error } = await supabase.from('positions').select()
            if(error) {
                snackbar.handleOpen(`Uable to get list of positions`, "error")
            }

            setPositions(data)
        }

        getOrganisationRolesList()
    }, [snackbar])

    // Submit form
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform validation
        const validationErrors = {};

        if (!password) {
            validationErrors.password = 'Password is required';
        }
        if (!retypePassword) {
            validationErrors.retypePassword = 'Retype password is required';
        }

        if(password !== retypePassword) {
            validationErrors.retypePassword = 'Passwords do not match';
        }

        if (name.length < 2) {
            validationErrors.name = 'Organisation name must be longer than 2 characters';

        }

        if (!selectedPosition) {
            validationErrors.selectedPosition = 'Position is required';
        }

        if(password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long';
        }

        const getOrganisationRes = await supabase
                .from('organisations')
                .select('name')

        const foundOrganisationName = getOrganisationRes.data.find((organisationListItem) => organisationListItem.name.toLowerCase().replace(/\s/g, '') === name.toLowerCase().replace(/\s/g, ''))

        
        if(getOrganisationRes.error) {
            validationErrors.name = getOrganisationRes.error.toString();
        }

        if(!!foundOrganisationName) {
            validationErrors.name = 'Organisation with this name already exists';
        }

        if(firstName.length < 2) {
            validationErrors.firstName = 'First name must be longer than 2 characters';
        }

        if(lastName.length < 2) {
            validationErrors.lastName = 'Last name must be longer than 2 characters';
        }

        // Update errors state
        setErrors(validationErrors);

        // Submit form if there are no validation errors
        if (Object.keys(validationErrors).length === 0) {
            // Submit logic here
            const updateUserRes = await supabase.auth.updateUser({
                password: password,
                data: {
                    first_name: firstName,
                    last_name: lastName
                }
            })

            if(updateUserRes.error) {
                snackbar.handleOpen(updateUserRes.error.message, "error")
                return;
            }

            const addOrganisationRes = await supabase
                .from('organisations')
                .insert({ name })
                .select('id')

            if(addOrganisationRes.error) {
                snackbar.handleOpen(addOrganisationRes.error.message, "error")
                return;
            }

            const addOrganisationRoleRes = await supabase
                .from('organisation_roles')
                .insert({ organisation_id: addOrganisationRes.data[0].id, name: "admin" })
                .select('id')

            if(addOrganisationRoleRes.error) {
                snackbar.handleOpen(addOrganisationRoleRes.error.message, "error")
                return;
            }

            const addEmployeeRes = await supabase
                .from('employees')
                .insert({
                    position_id: selectedPosition,
                    organisation_id: addOrganisationRes.data[0].id,
                    user_id: updateUserRes.data.user.id,
                    organisation_role_id: addOrganisationRoleRes.data[0].id,
                    is_organisation_creator: true
                })
            
            if(addEmployeeRes.error) {
                snackbar.handleOpen(addEmployeeRes.error.message, "error")
                return;
            }

            snackbar.handleOpen("User created successfully", "success")
            setTimeout(() => {
                history.push("/login")
            }, 1500)
        }
    };

    const handleTypePassword = (e) => {
        const newPassword = e.target.value;
        const validationErrors = {};

        // Perform password validation
        if (newPassword.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long';
        }
        if (!/\d/.test(newPassword)) {
            validationErrors.password = 'Password must contain at least one digit';
        }
        if (!/[a-zA-Z]/.test(newPassword)) {
            validationErrors.password = 'Password must contain at least one letter';
        }

        // Update errors state
        setErrors(validationErrors);
        setPassword(newPassword);
    };

    const handlePasswordMatch = (e) => {
        const newPassword = e.target.value;
        const validationErrors = {};

        if (password !== newPassword) {
            validationErrors.retypePassword = 'Passwords do not match';
        }

        setErrors(validationErrors);
        setRetypePassword(newPassword);
    };

    const handleSetOrganisationName = (e) => {
        const organisationName = e.target.value;
        const validationErrors = {};

        if (organisationName.length < 2) {
            validationErrors.name = 'Organisation name must be longer than 2 characters';

        }
        setErrors(validationErrors);
        setName(e.target.value);
    }

    const handleTypeFirstName = (e) => {
        const firstName = e.target.value;
        const validationErrors = {};

        if (firstName.length < 2) {
            validationErrors.firstName = 'First name must be longer than 2 characters';
        }

        setErrors(validationErrors);
        setFirstName(e.target.value);
    }

    const handleTypeLastName = (e) => {
        const lastName = e.target.value;
        const validationErrors = {};

        if (lastName.length < 2) {
            validationErrors.lastName = 'Last name must be longer than 2 characters';
        }

        setErrors(validationErrors);
        setLastName(e.target.value);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>    
                <TextField
                    label="Name of Organization"
                    value={name}
                    onChange={handleSetOrganisationName}
                    error={!!errors.name}
                    helperText={errors.name}
                    fullWidth
                /> 
                <FormControl error={!!errors.selectedPosition}>
                    <InputLabel id="position-select">Position Select</InputLabel>
                    <Select
                        value={selectedPosition}
                        onChange={(e) => setSelectedPosition(e.target.value)}
                        error={!!errors.selectedPosition}
                        helperText={errors.selectedPosition}
                        label="Position Select"
                        labelId="position-select"

                        fullWidth
                    >
                        <MenuItem value="" disabled><em>Select position</em></MenuItem>
                        {positions.map((position) => (
                            <MenuItem value={position.id} key={position.id}>
                                {position.name}
                            </MenuItem>
                        ))}  
                    </Select>
                    <FormHelperText error={!!errors.selectedPosition}>{errors.selectedPosition}</FormHelperText>
                </FormControl>
                <TextField
                    label="First Name"
                    type="text"
                    value={firstName}
                    onChange={handleTypeFirstName}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    fullWidth
                />

                <TextField
                    label="Last name"
                    type="text"
                    value={lastName}
                    onChange={handleTypeLastName}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    fullWidth
                />

                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handleTypePassword}
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                />
                <TextField
                    label="Retype password"
                    type="password"
                    value={retypePassword}
                    onChange={handlePasswordMatch}
                    error={!!errors.retypePassword}
                    helperText={errors.retypePassword}
                    fullWidth
                />
                <Button disabled={Object.keys(errors).length > 0} type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Stack>
        </form>
    );
};

export default NewOrganisation;