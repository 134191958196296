export const allCFTypeOptions = [
    { value: 1, label: "Inflows" },
    { value: 2, label: "Coupon" },
    { value: 3, label: "Dividend" },
    { value: 10, label: "Cost" },
    { value: 11, label: "Management Fee" },
    { value: 12, label: "Performance Fee (Carried Interest Paid)" },
    { value: 13, label: "Deal Fee" },
    { value: 14, label: "Fund Operations Fee" },
    { value: 15, label: "Start-Up Fund Fee" },
    { value: 16, label: "Hurdle Catch-Up Fee" },
    { value: 17, label: "Catch Up Paid" },
    { value: 50, label: "Payouts" },
    { value: 51, label: "Commitment" }
]