import { capitalizeFirstLetter } from 'utils/stringManipulators';
import { arrayListsToArrayObjects } from "utils/apiDataHelpers";

const getFullName = (firstName, middleName, lastName) => {
    let fullName = ""
    if(firstName && firstName.length) {
        fullName += capitalizeFirstLetter(firstName)
    }

    if(middleName && middleName.length) {
        fullName += ` ${capitalizeFirstLetter(middleName)}`
    }

    if(lastName && lastName.length) {
        fullName += ` ${capitalizeFirstLetter(lastName)}`
    }

    return fullName
}
export const handleCleanCustomerData = (c, riskProfileData = undefined) => {
    const customer = arrayListsToArrayObjects(c)[0]

    if(customer.Account) {
        customer.Account = arrayListsToArrayObjects(c.Account);
    }
    if(customer.Depot) {
        customer.Depot = arrayListsToArrayObjects(c.Depot);
    }

    const riskProfileNumber = Array.isArray(customer.RiskProfileCategory) ? customer.RiskProfileCategory[0] : customer.RiskProfileCategory
    let riskProfileNumberValue = ""
    if(riskProfileNumber === 0) {
        riskProfileNumberValue = "Not submitted"
    } else {
        riskProfileNumberValue = riskProfileNumber
    }
    
    let onboarding;
    if (customer.KYCDone && customer.RiskProfileCategory) {
        onboarding = 100;
    } else if (customer.KYCDone) {
        onboarding = 50;
    } else {
        onboarding = 0;
    }

    let riskProfileInformation = null;
    if(riskProfileNumber > 0 && riskProfileData) {
        const index = Number(customer.RiskProfileCategory) - 1

        riskProfileInformation = {
            AllowedInvestmentUniverse: riskProfileData["Allowed_Investment_Universe"][index],
            DkFSAClassification: riskProfileData["DK_FSA_Classification"][index],
            Horizon: riskProfileData.Horizon[index],
            RiskProfileCategory: riskProfileData.RiskProfileCategory[index]
        };
    }

    return {
        id: customer.CustomerID,
        CustomerType: customer.CustomerType  || "",
        EMail: customer.EMail || "",
        name: getFullName(customer.FirstName, customer.MiddleName, customer.LastName),
        FirstName: customer.FirstName ? capitalizeFirstLetter(customer.FirstName) : "",
        MiddleName: customer.MiddleName ? capitalizeFirstLetter(customer.MiddleName) : "",
        LastName: customer.LastName ? capitalizeFirstLetter(customer.LastName) : "",
        riskProfileInformation,
        isCustomerOnboarded: onboarding === 100,
        kycDone: customer.KYCDone === 1,
        onboarding,
        riskProfile: riskProfileNumberValue,
        numberOfPortfolios: customer.NoOfPortfolios,
        fetched: false,
        portfolios: [],
    }
}

export const handleMapCustomersById = (customers) => {
    const mapped = {}
    customers.forEach((el) =>{
        mapped[el.id] = el
    })
    return mapped;
}