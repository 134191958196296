import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors, } from './styles/variables';
import { purple } from "@mui/material/colors";


const { palette, spacing } = createTheme();
let theme = createTheme({
  palette: {
    myAwesomeColor: palette.augmentColor({ color: purple }),
    background: {
      default: '#E6E3D9' // '#f0ebd8'
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
      dark: colors.errorDark,
      contrastText: colors.errorContrast
    },
    primary: {
      main: '#363445',
      light: '#B3C5CE',
      dark: '#003F5C',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: colors.primaryDark
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
      light: colors.warningLight,
      dark: colors.warningDark,
      contrastText: colors.warningContrast
    },
    text: {
      main: colors.textprimary,
      light: colors.textSecondary
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
          elevation0: {
            backgroundColor: "rgba(255,255,255, 0.75)",
          },
          elevation1: {
            backgroundColor: "rgba(255,255,255, 1)",
          },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: "0px !important",
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "0px",
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: spacing(1),
          fontSize: '0.625rem',
          minWidth: '72px'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // borderColor: "rgba(0, 0, 0, 0.1)",
          '--DataGrid-rowBorderColor': 'rgba(0,0,0, 0.1)',
        },
        columnHeaderTitle: {
          fontSize: "0.825rem",
          fontWeight: "bold",
        },
        columnHeader: {
          height: "30px !important",
        },

        columnHeaders: {
          border: "none",
          // backgroundColor: "white"
        },
        columnHeaderRow: {
          backgroundColor: "trasparent !important",
        },

        cell: { 
          display: "flex",
          alignItems: "center",
          fontSize: "11px",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #f2f2f2"
        }
      }
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     indicator: {
    //       backgroundColor: "red"
    //     },
    //   }
    // }
    // MuiDrawer: {
    //     styleOverrides: {
    //         paper: {
    //             backgroundColor: "blue"
    //         }
    //     }
    // }
  },
  typography: {
    variants: [
      {
        props: { variant: 'cell' },
        style: {
          fontSize: '11px',
          fontFamily: 'Lato, Arial, sans-serif',
        },
      },
    ],
    fontFamily: 'Lato, Arial, sans-serif',
    h1: {
      fontFamily: 'Playfair Display, serif',
      fontWeight: 700, // Bold weight
      lineHeight: 1.5,
      fontSize: '3rem',
    },
    h2: {
      fontFamily: 'Playfair Display, serif',
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Playfair Display, serif',
      lineHeight: 1.5,
      fontSize: '1.5rem',
    },
    h4: {
      fontFamily: 'Lato, Arial, sans-serif',
      fontSize: '1.5rem',
      lineHeight: 1.5,
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h5: {
      fontFamily: 'Playfair Display, serif',
      lineHeight: 1.5,
      fontSize: '1rem',

    },
    h6: {
      
      lineHeight: 1.5,
      fontSize: '1rem',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
    subtitle1: {
      fontFamily: 'Lato, Arial, sans-serif',
      fontSize: '0.875rem',
    },
    subtitle2: {
      fontFamily: 'Lato, Arial, sans-serif',
      fontSize: '0.75rem',
    },
    body1: {
      fontFamily: 'Lato, Arial, sans-serif',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    body2: {
      fontFamily: 'Lato, Arial, sans-serif',
      lineHeight: 1.5,
    },
    button: {
      fontFamily: 'Lato, Arial, sans-serif',
    },
    caption: {
      fontFamily: 'Lato, Arial, sans-serif',
    },
    overline: {
      fontFamily: 'Lato, Arial, sans-serif',
    },
    cell: {
      fontSize: '11px',
      fontFamily: 'Lato, Arial, sans-serif',
    }
  },
  spacing: 6,
  shape: {
    borderRadius: 8,
  },
});

theme = responsiveFontSizes(theme);

export {theme};
