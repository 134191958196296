import call, { internalapiCall } from 'api/call';
import { runInAction } from 'mobx';
import * as Sentry from "@sentry/react";

export const mobXStoreHelper = async (url, params = null, cb = null, method = "POST") => {

    try {
        const response = await call(url, params, method);
        if (response.success) {
            runInAction(() => {
                cb(response)
            })
        } else {
            Sentry.captureException(new Error(response.meta.data.ErrorText));
        }
    } catch(error) {
        Sentry.captureException(new Error(error));
    }
}

export const mobXStoreInternalApiHelper = async (method, url, params = null, cb) => {
    try {
        const response = await internalapiCall(method, url, params);

        runInAction(() => {
            cb(response)
        })
    } catch(error) {
        Sentry.captureException(new Error(error));
    }
}