import { widgetCategoriesStaticData } from './constants';

export const widgetConstructor = (id, headline, category, type) => {
  return {
    id,
    headline,
    category,
    type,
    ...widgetCategoriesStaticData[type]
  }
}