import call from 'api/call';
import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getPreviousWorkday, getTodayByNumber } from "utils/dateFormatters";
import snackbar from "./snackbarStore";
import * as Sentry from "@sentry/react";

class DataStore {
    currencyCodes = JSON.parse(localStorage.getItem("currencyCodes")) || [];
    currencyCode = localStorage.getItem("currencyCode") || "DKK";
    previousBankDate = localStorage.getItem("previousBankDate") || getPreviousWorkday();
    today = getTodayByNumber();
    calcDate = Number(localStorage.getItem("calcDate")) || getTodayByNumber();
    
    constructor() {
        makeObservable(this, {
            currencyCodes: observable,
            currencyCode: observable,
            setCurrencyCode: action.bound,
            fetchCurrencyCodes: action.bound,
            previousBankDate: observable,
            fetchPreviousBankDate: action.bound,
            today: observable,
            setCalcDate: action.bound,
            calcDate: observable,
        });
    }

    setCurrencyCode(value) {
        this.currencyCode = value;
        localStorage.setItem("currencyCode", value);
    }

    setCalcDate(switchBoolValue) {
        if(switchBoolValue === true) {
            this.calcDate = this.today;
            localStorage.setItem("calcDate", this.today);

        } else {
            this.calcDate = this.previousBankDate;
            localStorage.setItem("calcDate", this.previousBankDate);
        }

    }

    async fetchPreviousBankDate() {
        try {
            const response = await call(APIS.GET_PREVIOUS_BANK_DATE, {});
            if(response.success) {
                runInAction(() => {
                    localStorage.setItem("previousBankDate", response.meta.data.PreviousBankDate)
                })
            } else {
                snackbar.handleOpen("Unable to fetch previous bank date", "error")
                Sentry.captureException(new Error(response.meta.data.ErrorText));
            }
        } catch (e) {
            Sentry.captureException(new Error(e));
        }
    }

    async fetchCurrencyCodes() {
        try {
            const response = await call(APIS.GET_CURRENCY_CODE_LIST);

            if (response.success) {
            
                runInAction(() => {
                    localStorage.setItem("currencyCodes", JSON.stringify(response.message.CurrencyCodes))
                    this.currencyCodes = response.message.CurrencyCodes
                })
            } else {    
                snackbar.handleOpen("Unable to fetch currency codes", "error")
                Sentry.captureException(new Error(response.meta.data.ErrorText));
            }
        } catch(error) {
            snackbar.handleOpen("Unable to fetch currency codes", "error")
            Sentry.captureException(new Error(error));
        }
    }
}


const dataStore = (window.dataStore = new DataStore());

export default dataStore;
