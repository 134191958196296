import { APIS } from 'api/constants';
import { action, makeObservable, observable } from 'mobx';
import snackbar from "./snackbarStore";
import { v4 as uuidv4 } from 'uuid';
import {mobXStoreHelper} from "utils/mobXStoreHelper" 
import portfoliosStore from "./portfoliosStore";
import { dataKeys, dataKeysFilters, dataKeysTableGroups, dataKeysElementIds } from "Pages/Portfolio/constants";

const handleCleanComplianceRules = (complianceRulesObject, selectedComplianceRuleState) => {
    const data = complianceRulesObject[selectedComplianceRuleState]
    const cleaned = {}
    // Asset naming is different than the rest
    if (data.Tolerance ){cleaned.Tolerance = data.Tolerance}
    if (data.MinWeight) {cleaned.MinWeight = data.MinWeight}
    if (data.MaxWeight) {cleaned.MaxWeight = data.MaxWeight}
    if (data.MinValue) {cleaned.MinValue = data.MinValue}
    if (data.MaxValue) {cleaned.MaxValue = data.MaxValue}
    if (selectedComplianceRuleState === dataKeys.Global_ComplianceRules && data.RiscFactor) {cleaned.RiscFactor = data.RiscFactor}  
    if (selectedComplianceRuleState === dataKeys.AssetID_ComplianceRules && data.AssetID) {cleaned.AssetID = data.AssetID} 
    if (selectedComplianceRuleState === dataKeys.AssetIDTotal_ComplianceRules && data.AssetID) {cleaned.AssetID = data.AssetID}
    if (selectedComplianceRuleState === dataKeys.Group_ComplianceRules && data.GroupName) {cleaned.GroupName = data.GroupName} 
    if (selectedComplianceRuleState === dataKeys.Sector_ComplianceRules && data.SectorName) {cleaned.SectorName = data.SectorName}
    if (selectedComplianceRuleState === dataKeys.RiskClass_ComplianceRules && data.RiskClassification) {cleaned.RiskClassification = data.RiskClassification}
    if (selectedComplianceRuleState === dataKeys.CurrencyExposure_ComplianceRules && data.CurrencyCode) {cleaned.CurrencyCode = data.CurrencyCode}
    if (selectedComplianceRuleState === dataKeys.BondIssuerExposure_ComplianceRules && data.IssuerName) {cleaned.IssuerName = data.IssuerName}
    if (selectedComplianceRuleState === dataKeys.BondTypeExposure_ComplianceRules && data.BondType) {cleaned.BondType = data.BondType}
    
    
    return { [selectedComplianceRuleState]: cleaned };
}

const initialOptions = {
    assets: [],
    groups: [],
    sectors: [],
    bondTypes: [],
    bondIssuers: [],
}
const initialRules = [];

class RiskLimitRulesStore {
    loading = false;
    rules = initialRules;
    options = initialOptions;
    
    constructor() {
        makeObservable(this, {
            loading: observable,
            rules: observable,
            options: observable,
            get: action.bound,
            update: action.bound,
        });
    }

    async get(customerId, portfolioId) {
        this.options = initialOptions;
        this.rules = initialRules;
        this.loading = true;

        const params = { CustomerID: Number(customerId), PortfolioName: portfolioId };
        mobXStoreHelper(APIS.GET_COMPLIANCE_RULES, params, (response) => {

            let rules = []
            Object.keys(dataKeysFilters).forEach((filterKey) => {
                const filter = dataKeysFilters[filterKey];
                const hasData = response.message[filter] && response.message[filter].length
                const idKey = dataKeysElementIds[filterKey];
                
                const groupName = dataKeysTableGroups[filterKey]
                const value = dataKeys[filterKey];

                if(hasData) {
                    const newRules = response.message[filter].map((el) => {
                        const name = (filter === dataKeysFilters.AssetID_ComplianceRules ||filter === dataKeysFilters.AssetIDTotal_ComplianceRules) ? el.AssetName : el[idKey]
                        // const some = `${el[idKey]} ${(filter === "AssetIDActual_ComplianceRules" || filter === "AssetIDActualTotal_ComplianceRules") ? ` - ${el.AssetName } - ${el.CurrencyCode}` : ""}`
                        const id = uuidv4()
                        return {
                            ...el,
                            id,
                            IDName: el[idKey],
                            name,
                            type: value,
                            hierarchy: [groupName, el[idKey]],
                        }
                    })

                    const formattedData = [...rules, ...newRules]
                    
                    rules = formattedData
                }
            })


            this.options.sectors = response.message.SectorActual_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.Sector_ComplianceRules], value: el[dataKeysElementIds.Sector_ComplianceRules] })); 
            this.options.assets = response.message.AssetIDActual_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.AssetID_ComplianceRules], value: el[dataKeysElementIds.AssetID_ComplianceRules] }));
            this.options.assetsTotal = response.message.AssetIDActualTotal_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.AssetIDTotal_ComplianceRules], value: el[dataKeysElementIds.AssetIDTotal_ComplianceRules] }));
            this.options.bondTypes = response.message.BondTypeExposure_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.BondTypeExposure_ComplianceRules], value: el[dataKeysElementIds.BondTypeExposure_ComplianceRules] }));
            this.options.bondIssuers = response.message.BondIssuerExposure_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.BondIssuerExposure_ComplianceRules], value: el[dataKeysElementIds.BondIssuerExposure_ComplianceRules] }));
            this.options.groups = response.message.Group_ComplianceRules.map((el) => ({ name: el[dataKeysElementIds.Group_ComplianceRules], value: el[dataKeysElementIds.Group_ComplianceRules] }));

            this.rules = rules;
            this.loading = false;
        })
    }

    async update(customerId, portfolioId, complianceRules, selectedComplianceRuleState, handleClose) {
        this.loading = true;
        const cleanedComplianceRules = handleCleanComplianceRules(complianceRules, selectedComplianceRuleState)
        const params = { CustomerID: Number(customerId), PortfolioName: portfolioId, ...cleanedComplianceRules }

        mobXStoreHelper(APIS.UPDATE_COMPLIANCE_RULES, params, async () => {
            handleClose()
            await portfoliosStore.fetchCustomerPortfolioDetails(customerId, portfolioId)
            await this.get(customerId, portfolioId)
            snackbar.handleOpen("Risk limits successfully updated", "success")
            this.loading = false;
        })
    }
}

const riskLimitRulesStore = (window.riskLimitRulesStore = new RiskLimitRulesStore());

export default riskLimitRulesStore;
